export const months = [
  { label: "Január", value: 1, color: "cyan", emoji: "☃" },
  { label: "Február", value: 2, color: "cyan", emoji: "☃" },
  { label: "Március", value: 3, color: "green", emoji: "🍃" },
  { label: "Április", value: 4, color: "green", emoji: "🍃" },
  { label: "Május", value: 5, color: "green", emoji: "🍃" },
  { label: "Június", value: 6, color: "red", emoji: "🌞" },
  { label: "Július", value: 7, color: "red", emoji: "🌞" },
  { label: "Augusztus", value: 8, color: "red", emoji: "🌞" },
  { label: "Szeptember", value: 9, color: "gold", emoji: "🍂" },
  { label: "Október", value: 10, color: "gold", emoji: "🍂" },
  { label: "November", value: 11, color: "gold", emoji: "🍂" },
  { label: "December", value: 12, color: "cyan", emoji: "☃" },
];
export const monthsColor = {
  1: "cyan",
  2: "cyan",
  3: "green",
  4: "green",
  5: "green",
  6: "red",
  7: "red",
  8: "red",
  9: "gold",
  10: "gold",
  11: "gold",
  12: "cyan",
};
